<template>
    <div id="vr-content">
        <img src="../assets/image/new_return.png" alt class="go-back" @click="returnHome" />
        <iframe :src="vrUrl" frameborder="0" width="100%" height="100%">Sorry, your browser does not support
            iframes.</iframe>
    </div>
</template>

<script>
    export default {
        name: "webActLegongchang1",
        props: {},
        data() {
            return {
                vrUrl: '',
                beforePage: false
            }
        },
        created() {
            this.getDetail();
        },
        beforeRouteEnter(to, from, next) {
            if (from.name == null) {
                next(vm => {
                    vm.beforePage = true
                })
            } else {
                next()
            }
        },
        methods: {
            getDetail() {
                let that = this;
                this.$axios({
                    method: "POST",
                    url: "api/v5/panorama/channelDetails",
                    data: {
                        panorama_id: this.$route.query.panorama_id,
                        channel_id: this.$route.query.channel_id,
                        id: this.$route.query.rent_id == undefined ? '' : this.$route.query.rent_id
                    }
                }).then(res => {
                    if (res.data.status == 200) {
                        this.vrUrl = res.data.data.url
                    }
                })
            },
            returnHome() {
                if (!this.beforePage) {
                    this.$router.go(-1)
                } else {
                    this.$router.push({
                        path: "/"
                    })
                }
            }
        },
        mounted() {

        }
    }
</script>

<style scoped lang="scss">
    .home-pop {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 13;
        box-sizing: border-box;
        /* background:rgba(0,0,0,.3); */
        background: #292b2d;

        .big-img {
            width: 70%;
            height: auto;
        }

        .close {
            position: absolute;
            top: 60px;
            right: 160px;
            width: 40px;
            height: 40px;
            z-index: 80;
            cursor: pointer;
        }
    }

    .contact-info>div>div {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: PingFangSC-Medium, PingFang SC;
    }

    .contact-title {
        font-size: 0.24rem;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 0.24rem;
        margin-top: 0.1rem;
    }

    .contact-name {
        font-size: 0.4rem;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 0.4rem;
        transition: all 0.3s;
    }

    .contact-name.active {
        font-size: 0.32rem;
        line-height: 0.32rem;
    }

    .contact-title.active {
        font-size: 0.24rem;
        line-height: 0.24rem;
        margin-top: 0.12rem;
    }

    .contact-headimg {
        position: absolute;
        left: 0.34rem;
        bottom: 0.56rem;
        width: 1.5rem;
        height: 1.5rem;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
        border-radius: 0.02rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
    }

    .contact-headimg img {
        width: 1.3rem;
        height: 1.3rem;
        object-fit: cover;
        display: block;
        transition: all 0.3s;
    }

    .contact-headimg.active {
        width: 0.9rem;
        height: 0.9rem;
        box-shadow: none;
    }

    .contact-headimg.active img {
        width: 100%;
        height: 100%;
    }

    .user-headimg {
        width: 0.9rem;
        height: 0.9rem;
        flex: none;
    }

    .user-info {
        flex: 1;
        padding: 0 0.4rem;
        overflow: hidden;
    }

    .user-name {
        font-size: 0.32rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 0.32rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .user-title {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 0.24rem;
        margin-top: 0.12rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .contact-btn {
        flex: none;
        font-size: 0.26rem;
        font-family: SourceHanSansSC-Normal, SourceHanSansSC;
        font-weight: 400;
        color: rgba(54, 69, 90, 1);
        line-height: 0.26rem;
        padding: 0.12rem 0.28rem;

        background: rgba(248, 248, 248, 1);
        border-radius: 0.24rem;
    }

    .user-headimg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .user-list {
        width: 100%;
        height: auto;
        max-height: 8rem;
        box-sizing: border-box;
        padding: 0 0.34rem;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .user-contact {
        width: 100%;
        height: 1.5rem;
        border-bottom: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
    }

    .user-contact:last-child {
        border: none;
    }

    .users-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: 12;
    }

    .user-content {
        width: 100%;
        height: auto;
        padding-bottom: 1.62rem;
        background: rgba(255, 255, 255, 1);
        border-radius: 0.4rem 0.4rem 0px 0px;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .user-header {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.9rem 0.34rem 0.3rem;
        font-size: 0.4rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 0.4rem;
    }

    .user-header img {
        width: 0.4rem;
        box-sizing: content-box;
        padding: 0.1rem 0 0.1rem 0.6rem;
    }

    .contact-user {
        width: 100%;
        /* max-width: 800px; */
        height: 1.62rem;
        z-index: 13;
        position: absolute;
        left: 0;
        bottom: -2.1rem;
        background-color: #fff;
        transition: bottom 0.3s;
    }

    .contact-user.active {
        bottom: 0;
    }

    .contact-info {
        width: calc(100% - 2.24rem);
        height: 1.06rem;
        position: absolute;
        right: 0.14rem;
        top: 0;
        display: flex;
        align-items: center;
        transition: width 0.3s;
    }

    .contact-info.active {
        width: calc(100% - 1.79rem);
    }

    .contact-info>div {
        flex: 1;
        overflow: hidden;
        padding-right: 0.2rem;
    }

    .contact-icon {
        width: 0.7rem;
        height: 0.7rem;
        box-sizing: content-box;
        display: block;
        padding: 0.2rem 0.2rem 0.2rem 0;
        flex: none;
    }



    .pannel-page {
        width: 100%;
        /* max-width: 800px; */
        height: 100vh;
        background-color: #fff;
        z-index: 11;
        position: absolute;
        left: -110vw;
        top: 0;
        padding: 0 0 1.62rem;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pannel-page.active {
        left: 0;
    }

    .pannel-page img {
        width: 100%;
        max-width: 800px;
        height: auto;
        display: block;
    }

    .act-list {
        width: 100%;
        /* max-width: 800px; */
        height: 100vh;
        background-color: #fff;
        z-index: 11;
        position: absolute;
        left: -110vw;
        top: 0;
        padding: 0.88rem 0 2.6rem;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
    }

    .act-list.active {
        left: 0;
    }

    @import "../assets/css/animate.min.css";

    ::-webkit-scrollbar {
        display: none;
    }

    @keyframes vrIcon {

        /* 0%,
    20% {
      background-position: 0 0;
    }
    to {
      background-position: 0 100%;
    } */
        0% {
            background-position: 0 0;
        }

        100% {
            background-position: 0 100%;
        }
    }

    @keyframes turn {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    .scene-group {
        position: absolute;
        left: 0.34rem;
        width: calc(100% - 0.34rem);
        height: auto;
        bottom: 0.6rem;
        z-index: 2;
    }

    .scene-item {
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 0.02rem;
        margin: 0.32rem 0 0.32rem 0.24rem;
        flex: none;
        transition: all 0.1s;
        position: relative;
    }

    .scene-item .name {
        position: absolute;
        background: rgba(255, 255, 255, 0.3);
        text-shadow: 0px 1px 2px rgba(93, 47, 0, 0.41);
        color: #333;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        max-height: 100%;
        overflow: hidden;
        text-align: center;
        font-size: 10px;
        word-break: break-all;
    }

    .scene-item.active {
        box-shadow: #ffffff 0px 0px 10px;
    }

    .scene-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .scene-item:last-child {
        margin-right: 0.24rem;
    }

    .group-item {
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 0.52rem;
        width: auto;
        padding: 0 0.44rem;
        height: 0.52rem;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0.04rem;
        margin: 0.24rem 0 0.24rem 0.2rem;
        flex: none;
        transition: all 0.1s;
    }

    .group-item.active {
        background: linear-gradient(192deg,
                rgba(243, 223, 198, 1) 0%,
                rgba(205, 166, 130, 1) 100%);
        color: rgba(93, 47, 0, 1);
        text-shadow: 0px 1px 2px rgba(93, 47, 0, 0.41);
    }

    .group-item:last-child {
        margin-right: 0.2rem;
    }

    .group-name {
        width: 100%;
        overflow: hidden;
        height: auto;
        margin-top: 1px;
        background: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .row-group {
        position: absolute;
        top: 1.7rem;
        left: 0rem;
        width: 2.6rem;
        height: auto;
        background: rgba(0, 0, 0, 0.1);
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 2;
    }

    .lower-height {
        top: 50%;
        transform: translateY(-50%);
    }

    .row-group-item {
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 0.52rem;
        width: 2.2rem;
        height: 0.52rem;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0.04rem;
        margin: 0.24rem auto 0.24rem;
        flex: none;
        transition: all 0.1s;
    }

    /* .row-group-item:last-child {
    margin-right: 0;
  } */
    .row-group-item.active {
        background: linear-gradient(192deg,
                rgba(243, 223, 198, 1) 0%,
                rgba(205, 166, 130, 1) 100%);
        color: rgba(93, 47, 0, 1);
        text-shadow: 0px 1px 2px rgba(93, 47, 0, 0.41);
    }

    .scene-name {
        width: 100%;
        height: auto;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        position: relative;
        -webkit-overflow-scrolling: touch;
    }

    .row_scene {
        position: absolute;
        top: 1.7rem;
        right: 0rem;
        width: 1.78rem;
        height: calc(100% - 1rem);
        overflow: hidden;
        background: rgba(0, 0, 0, 0.1);
        /* display: flex;
    align-items: center;
    justify-content: flex-start; */
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 2;
    }

    #vr-content {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: relative;

    }

    .go-back {
        width: 44px;
        height: 36px;
        position: absolute;
        top: 77px;
        left: 72px;
        z-index: 12;
        cursor: pointer;
    }

    .home-content {
        position: absolute;
        width: 80px;
        top: 77px;
        right: 72px;
        z-index: 12;
        cursor: pointer;
    }

    .ipad-content {
        position: absolute;
        width: 80px;
        top: 140px;
        left: 72px;
        z-index: 12;
        cursor: pointer;
    }

    .home-img {
        width: 60px;
        height: 60px;
    }

    .home-title {
        font-size: 20px;
        color: #fff;
        text-align: center;
    }

    #vr-content .full-screen {
        width: 100%;
        height: 100%;
    }

    #loading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 12;
        filter: grayscale(100%);
        filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
        color: #808695;
    }

    #loading-failed {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 12;
    }

    #home-page {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 10;
    }

    #home-page-bac {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    #home-page-bac .home-page-bac-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .home-page-btns {
        width: 100%;
        height: 0;
        background: linear-gradient(180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.9) 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all 0.3s;
    }

    .home-page-btns .active {
        height: 3.2rem;
    }

    .home-page-btns-content {
        width: 90%;
        height: 1.26rem;
        margin-left: 5%;
        position: absolute;
        bottom: 1rem;
        z-index: 10;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .home-page-btn {
        width: 25%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        transform: translateX(100vw);
    }

    .home-page-btn.active {
        transform: translateX(0);
    }

    .home-page-btn-img {
        width: 0.88rem;
        height: 0.88rem;
        /* object-fit: cover; */
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .home-page-btn-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .home-page-btn-bigger img {
        width: 0.88rem;
        height: 0.88rem;
    }

    @font-face {
        font-family: "Kt";
        src: url("https://static.comegogogo.com/static/font/kt.ttf") format("truetype");
    }

    .home-page-btn-text {
        display: block;
        width: 100%;
        min-height: 0.28rem;
        font-size: 0.28rem;
        font-family: Kt, PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(226, 197, 167, 1);
        line-height: 0.28rem;
        text-align: center;
        white-space: nowrap;
        /* overflow: hidden; */
        text-overflow: ellipsis;
        /* height: auto; */
        /* overflow-y: auto; */
    }

    .vr-logo {
        width: 45px;
        height: 45px;
        background: url(https://static.comegogogo.com/image/loading/vr-animation.png) no-repeat;
        background-size: 100%;
        -webkit-animation: vrIcon 3.5s steps(53) infinite;
        animation: vrIcon 3.5s steps(53) infinite;
    }

    .pano-outer {
        width: 100vw;
        height: 100vh;
        position: absolute;
        background: #fff;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity 1s;
    }

    .pano-outer.active {
        opacity: 1;
        z-index: 11;
    }

    .popPage {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: transparent;
    }

    #building-vr {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .return-icon {
        left: 0.34rem;
        top: 0.44rem;
    }

    .pv-num {
        position: absolute;
        left: 0;
        top: 1.38rem;
        z-index: 2;
        width: auto;
        height: 0.56rem;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0rem 0.44rem 0.44rem 0rem;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 0.24rem;
    }

    .pv-row-num {
        /* top: 1.68rem; */
        display: none;
    }

    .phone-icon {
        right: 0.34rem;
        top: 1.38rem;
    }

    .return-row-icon {
        width: 0.68rem;
        height: 0.68rem;
        top: .3rem;
        left: .34rem;
    }

    .audio-row-icon {
        display: none;
    }

    .phone-row-icon {
        display: none;
    }

    .full-row-icon {
        width: 0.68rem;
        height: 0.68rem;
        top: .3rem;
        right: .34rem;
    }

    .ios-full {
        display: none;
    }

    .cover-home-img {
        width: 100vw;
        height: 100vh;
        box-sizing: border-box;
        padding-bottom: 2.26rem;
        object-fit: contain;
        object-position: center;
        z-index: 9;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cover-home-img img {
        width: 5rem;
    }

    .header-icon {
        z-index: 12;
        left: -1rem;
        transition: left 0.1s;
    }

    .header-icon.active {
        left: 0.34rem;
        z-index: 20;
    }

    .act-item {
        width: calc(100% - 0.68rem);
        max-width: 800px;
        box-sizing: border-box;
        margin: 0.4rem auto 0;
        height: auto;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 12px 6px rgba(0, 0, 0, 0.04);
        border-radius: 0.08rem;
        overflow: hidden;
    }

    .act-cover-img {
        display: block;
        width: 100%;
        height: 0;
        padding-bottom: 40%;
        position: relative;
    }

    .act-cover-img img {
        width: 100%;
        display: block;
        height: 100%;
        position: absolute;
        object-fit: cover;
    }

    .act-name {
        width: auto;
        height: auto;
        font-size: 0.34rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 0.4rem;
        margin: 0.28rem 0 0.1rem 0.24rem;
    }

    .act-time {
        width: auto;
        height: auto;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 0.28rem;
        margin: 0 0 0.28rem 0.24rem;
    }

    .pannel-page2 {
        width: 100%;
        height: 100vh;
        background-color: #fff;
        z-index: 16;
        position: absolute;
        left: -110vw;
        top: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pannel-page2.active {
        left: 0;
    }

    .pannel-page2 img {
        width: 100%;
        max-width: 800px;
        height: auto;
        display: block;
    }

    .iframe-content {
        width: 100%;
        height: 100%;
    }

    .add-active {
        top: 0.44rem;
        right: 0.34rem;
        z-index: 20;
    }

    .row-left-active {
        top: 0;
        left: 0 !important;
    }

    .row-right-active {
        top: 0;
        right: 0;
    }

    /* 导航 */
    .lists-content {
        position: fixed;
        width: 100%;
        height: 1.18rem;
        bottom: 0;
        left: 0;
        z-index: 1000;
    }

    .lists-content>.content {
        width: 100%;
        height: .3rem;
    }

    .lists {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: .88rem;
        padding: 0 .3rem;
        text-align: center;
        box-sizing: border-box;
        background: #fff;

    }

    .lists>div {
        width: 100%;
        height: .88rem;
        margin: 0;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .lists>div::-webkit-scrollbar {
        display: none;
    }

    .list {
        display: inline-block;
        list-style: none;
        margin: 0 .2rem;
        align-items: center;
        font-size: .28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(187, 187, 187, 1);
    }

    .list-active {
        color: #e2c5a7;
        font-weight: 600;
        position: relative;
        font-size: .32rem;
        line-height: .8rem;
        border-bottom: 0.04rem solid #333333;
    }

    .loading {
        position: relative;
        width: 100%;
        height: 100%;
        background: #292b2d;
        z-index: 100;
    }

    .loader {
        position: absolute;
        top: calc(50% - 32px);
        left: calc(50% - 32px);
        width: 64px;
        height: 64px;
        border-radius: 50%;
        perspective: 800px;
    }

    .inner {
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .inner.one {
        left: 0%;
        top: 0%;
        animation: rotate-one 1s linear infinite;
        border-bottom: 3px solid #EFEFFA;
    }

    .inner.two {
        right: 0%;
        top: 0%;
        animation: rotate-two 1s linear infinite;
        border-right: 3px solid #EFEFFA;
    }

    .inner.three {
        right: 0%;
        bottom: 0%;
        animation: rotate-three 1s linear infinite;
        border-top: 3px solid #EFEFFA;
    }

    @keyframes rotate-one {
        0% {
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
        }

        100% {
            transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
        }
    }

    @keyframes rotate-two {
        0% {
            transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
        }

        100% {
            transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
        }
    }

    @keyframes rotate-three {
        0% {
            transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
        }

        100% {
            transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
        }
    }
</style>